/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, Dispatch, useRef, useMemo, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FiArrowRight, FiArrowLeft, FiMail, FiShoppingCart } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Link, useParams } from 'react-router-dom';
import api from '../../../../services/api';

import Input from '../../../../components/Forms/Input';
import Button from '../../../../components/Button';
import SelectSimple from '../../../../components/Forms/SelectSimple';

import { authTitle, eventName, gateKey } from '../../../../config';

import CurrencyInput from '../../../../components/Forms/MaskedCurrencyInput';
import { AnimatedDiv } from '../../styles';
import { Content } from './styles';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';

import { ActiveButton } from '../../index';
import Loader from '../../../../components/Loader';
import format from '../../../../utils/format';
import {
  ResponseMessage,
  IResponseMessage,
} from '../../../../components/ResponseMessage';


import { useAuth } from '../../../../hooks/Auth';
import { useLanguage } from '../../../../hooks/Language';
import { FaBarcode, FaCreditCard, FaInfoCircle, FaQrcode, FaReceipt, FaStar, FaTicketAlt } from 'react-icons/fa';
import AtualizarCadastroContainer from '../AtualizarCadastro';

import { useConfiguration } from '../../../../hooks/Configuration';

import CompleteSaleFields from '../CompleteSaleFields';
import WhatsappFixed from '../../../../components/WhatsappFloat';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import DiscountCoupon, { useCheckCuponCode } from './modules/DiscountCoupon';
import AddProductsComponent from './modules/AddProductsToCart';
import PaymentMethodForm from './modules/PaymentForm';
import ProductNotFound from './modules/ProductNotFound';
import UserNotSigned from './modules/UserNotSigned';
import ProductNotAvailable from './modules/ProductNotAvailable';

import useCart from './Functions/useCart';
import usePaymentContext from './Functions/usePaymentContext';
import DisplayCartPrice from './modules/DisplayCartPrice';
import DiscountCouponRules from './modules/DiscountCouponRules';
import { useSocket } from '../../../../hooks/Socket';
import calculateFinalPrice from './Functions/calculateFinalPrice';
import calculateDiscount from './Functions/calculateDiscount';
import calculateCartPrice from './Functions/calculateCartPrice';
import MaskedInput from '../../../../components/Forms/MaskedInput';
import generateInstallments from './Functions/generateInstallments';
import CreditCardForm from './modules/PaymentForm/modules/CreditCard';
import { rgba } from 'polished';

declare global {
  interface Window { fbq?: (command: string, event: string, options?: object) => void; }
}


interface IOptions {
  text?: string;
  label?: string;
  value: string | number;
}

interface IParams {
  product_hash: string;
}

interface ICoupon {
  label: string;
  value: string;
  price: number;
}

const DisableBox = () => {
  return <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 100000, bottom: 0, right: 0, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', border: '2px solid #ddd', borderRadius: '10px', background: 'rgba(255,255,255,1)', padding: '10px', paddingTop: '50px' }}>
    <p style={{ color: '#333', fontWeight: 'bold', fontSize: '16px', width: '200px', textAlign: 'center' }}>Selecione um ingresso <br /> para continuar</p>
  </div>
}


const PaymentMethod: React.FC = () => {
  const { configuration } = useConfiguration();
  const { eventHash, projectHash } = useParams<Record<string, any>>();
  const { cart, changeCart, setCart } = useCart();
  const { installmentValue, changeInstallment, paymentMethod, changePaymentMethod, sendPaymentRequest, changeCardInfo } = usePaymentContext();
  const [paymentStep, setPaymentStep] = useState('cart');
  const product = cart?.[0] || {};

  const [buyContext, setBuyContext] = useState<Record<string, any>>({});
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const { product_hash } = useParams<IParams>();
  const [defaultValue, setDefaultValue] = useState(0);
  const [amount, setAmount] = useState(0.00);

  const [quantity, setQuantity] = useState((product_hash === 'apoio' || product_hash === 'apoio-pos') ? 5 : 1);

  const [closed, setClosed] = useState(false);
  const [productNotFound, setProductNotFound] = useState(false);
  const [paymentType, setPaymentType] = useState('default');

  const { language } = useLanguage();

  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const [keepOneTicket, setKeepOneTicket] = useState('no');
  const [couponInfo, setCouponInfo] = useState<Record<string, any>>();
  const [courtesyCode, setCourtesyCode] = useState('');
  const { checkCuponCode } = useCheckCuponCode();


  const { socket } = useSocket();

  const applyCoupon = async (hash) => {

    if (hash === 'remove') {
      setCouponInfo({});
    }

    if (!hash) {
      return;
    }

    try {
      const couponValue = await checkCuponCode(hash, projectHash);

      setCouponInfo(couponValue);

    }
    catch (err) {
      return;
    }



  }


  const [responseMessage, setResponseMessage] = useState(
    {} as IResponseMessage,
  );

  const { translate } = useLanguage();

  const { user } = useAuth();


  const rebuildCart = (cartList) => {





    cartList.map(item => {

      let x = 0;
      if (!item?.quantity) {
        item.quantity = 0;
      }
      while (x < item?.quantity) {
        changeCart(item, 'add', cart);

        x = x + 1;
      }


    })

  }

  const moveToCart = () => {

    setPaymentStep('cart');

  }


  const moveToPayment = (cartList) => {

    if (cartList?.length < 1) {
      addToast({ title: 'Você deve selecionar ao menos 1 ingresso', type: 'info' });
      return;
    }
    setPaymentStep('payment');
  }

  const handleApplyCourtesy = async (data) => {

    if (!data.courtesy_hash) {
      addToast({ title: 'Adicione um código de cortesia', type: 'success' });
      return;
    }
    setLoading(true);
    try {

      const courtesyItem = await api.get(`/check-courtesy/${data.courtesy_hash}/${language}`);


      const newProduct = courtesyItem.data.product;

      changeCart(newProduct, 'add', cart);
      setCourtesyCode(data.courtesy_hash);
      changePaymentMethod('cortesy');

      setLoading(false);
      removeModal('courtesy')
      return;

    }
    catch (err) {

      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao verificar código', type: 'error' });
      setLoading(false);
      return;

    }

  }


  const load = useCallback(async (hash) => {




  }, [product_hash]);

  useEffect(() => {


    if (window?.fbq) {
      window?.fbq('track', 'InitiateCheckout');
    }

    /*
     socket?.emit('checkout', { user_hash: user?.user_hash, name: user?.name });
 
     socket?.on('checkout-message', (data) => {
 
 
 
       addToast({ title: `${data?.name}, sua compra foi processada`, type: 'info' })
 
     })
 */
    load(product_hash);
    setLoading(false);

  }, [product_hash])


  const handleChangeTypeSubscription = (type) => {
    setPaymentType(type);
    removeModal('modalSubscriptionType');
  }


  useEffect(() => {
    if (quantity && defaultValue) {
      setAmount(quantity * defaultValue);
    }
  }, [quantity, product, defaultValue, installmentValue])

  useEffect(() => {

    if (product_hash === 'courtesy') {

      addModal({
        theme: 'whiteModalMedium',
        key: "courtesy",
        closeButton: false,
        title: '', content: <aside style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start', gap: '15px', padding: '30px 0px' }}>
          <Form className="form"
            ref={formRef}
            onSubmit={handleApplyCourtesy}
            onKeyDown={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}
            onKeyPress={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}

          >

            <h2 style={{ color: '#333', width: '100%', margin: '10px 0px', textAlign: 'center', fontSize: '14px', lineHeight: '18px' }}>
              {translate('Insira seu código da sua cortesia')}
            </h2>

            <Input
              name="courtesy_hash"
              placeholder={translate('Código do cupom')}
            />

            <aside style={{ margin: '10px auto', display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Button type="submit">
                {translate('Enviar')}
                <FiArrowRight />
              </Button>
            </aside>

          </Form>
        </aside>

      })

    }
    else if (configuration && user && product?._id && product?.any_price !== 'yes') {
      addModal({
        theme: 'whiteModalMedium',
        key: "modalSubscriptionType",
        closeButton: false,
        title: '', content: <aside style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start', gap: '15px', padding: '30px 0px' }}><h2 style={{ color: '#333' }}>{translate('Escolha o tipo de inscrição')}</h2>
          <button
            className={


              'shadowButtonSelect'
            }
            type="button"
            onClick={() => handleChangeTypeSubscription('default')}
          >

            <p style={{ fontSize: '14px', color: '#333' }}>{translate('Para eu utilizar')}</p>

          </button>

          <button
            className={


              'shadowButtonSelect'
            }
            type="button"
            onClick={() => handleChangeTypeSubscription('gift')}
          >

            <p style={{ fontSize: '14px', color: '#333' }}>{translate('Para outra pessoa')}</p>

          </button>

          <button
            className={


              'shadowButtonSelect'
            }
            type="button"
            onClick={() => handleChangeTypeSubscription('group')}
          >

            <p style={{ fontSize: '14px', color: '#333' }}>{translate('Para 2 ou mais pessoas')}</p>

          </button>
        </aside>

      })
    }

  }, [])


  useEffect(() => {

    if (configuration && user) {

      if (product_hash === 'courtesy') {
        changePaymentMethod('courtesy');
      }
      else if (configuration.allow_credit_card !== 'no') {
        changePaymentMethod('credit_card');
      }
      else if (user.country === 'BR') {
        changePaymentMethod('boleto');
      }

    }

  }, [product, configuration, user])






  return (
    <>
      {loading && <Loader message={`${translate('Carregando')}...`} />}





      {ready && <AnimatedDiv visible={true} style={{ flexDirection: 'column', alignItems: 'center', paddingBottom: '100px' }}>
        {/*  <WhatsappFixed
          message={translate('Suporte Técnico')}
          text={`${translate('Estou finalizando minha inscrição no')} ${authTitle} - ${configuration?.current_event_id_response?.title} - ${product?.title}`}
  /> */}
        {responseMessage.active === true && (
          <ResponseMessage
            active
            type={responseMessage.type}
            title={responseMessage.title}
            description={responseMessage.description}
          />
        )}
        {responseMessage.active !== true && (
          <>

            <div className='buttonColored row-to-column gap-sm' style={{ width: '100%', justifyContent: 'space-between', padding: '5px 16px', borderRadius: '10px 10px 0px 0px', marginTop: '15px', maxWidth: '1400px', }}>
              <h2 className='buttonColored' style={{ textAlign: 'center', fontSize: '18px', color: '#fff' }}>Seja premium: {configuration?.title}</h2>

              <Link to={`/app/${configuration?.url}/login`}>  <button className='greenButton2 buttonColored' style={{ border: '1px solid #fff', padding: '5px', width: '200px' }}>MEUS PAGAMENTOS</button></Link>
            </div>
            <Content style={{ width: '100%', borderRadius: '0px 0px 10px 10px', }}>




              {couponInfo?.hash ? <nav style={{ marginTop: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#fff', color: 'rgb(0,150,0', border: '2px solid rgb(0,150,0)', padding: '10px 16px', borderRadius: '10px', fontSize: '12px', fontWeight: 'bold' }}><FaStar size={20} style={{ minWidth: '20px', marginRight: '10px' }} /><p>DESCONTO ESPECIAL {couponInfo.hash}</p></nav> : <></>}


              {/* product?.orientation ? <aside style={{ margin: '10px', fontSize: '16px', color: '#333', alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex' }}>{translate(product?.orientation)}</aside> : <></> */}

              <aside className='PaymentMethodContainer' style={{ minWidth: '100%' }}>
                <div style={{ maxWidth: '400px', display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>

                  <div className='outerBlock' style={{ border: cart?.length < 1 ? '2px solid #A71C20' : '1px solid #ddd' }}>
                    <AddProductsComponent coupon={couponInfo} unique={true} showAdditional={product_hash && product_hash !== 'courtesy' ? true : false} cart={cart} setCallback={(product, option, replace = false) => { changeCart(product, option, cart, replace) }} />
                  </div>

                  <aside style={{ display: 'flex', flexDirection: 'column', maxWidth: '340px', alignItems: 'center', justifyContent: ' flex-start' }}>



                    {product_hash !== 'courtesy' && product?.any_price !== 'yes' ? <DiscountCouponRules setCoupon={setCouponInfo} coupon={couponInfo} cart={cart} /> : <></>}

                    {product_hash !== 'courtesy' && product?.any_price !== 'yes' ? <DiscountCoupon setCoupon={setCouponInfo} coupon={couponInfo} cart={cart} /> : <></>}

                  </aside>

                </div>
                <div style={{ position: 'relative', maxWidth: '400px', display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>


                  {cart?.length < 1 ? <DisableBox /> : <></>}

                  <div className='outerBlock' style={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
                    <h2 style={{ margin: ' 0px', fontSize: '14px', color: '#333', borderBottom: '3px solid #A71C20', padding: '5px 0px', }}>{translate('2. Forma de pagamento')}</h2>
                    <select className='defaultBlock' onChange={(e) => changePaymentMethod(e?.target?.value)}>
                      <option selected={paymentMethod === 'credit_card'} value={"credit_card"}>Cartão de Crédito</option>
                      {configuration?.current_event_id_response?.allow_boleto !== 'no' ? <option selected={paymentMethod === 'boleto'} value={"boleto"}>Boleto</option> : <></>}
                      <option selected={paymentMethod === 'pix'} value={"pix"}>Pix</option>
                    </select>

                    {paymentMethod === 'credit_card' ?
                      <> <select className='defaultBlock fadeIn' onChange={(e) => installmentValue?.toString() !== e?.target?.value ? changeInstallment(e.target.value) : false}>

                        {generateInstallments(calculateFinalPrice({ cart, coupon: couponInfo, paymentMethod: 'credit_card', installments: 1 }))?.map(item => {
                          return <option value={item?.value}>{item?.label}</option>

                        })}


                      </select>

                        <Form style={{ width: '100%' }} onSubmit={() => { }}>

                          <CreditCardForm changeCardInfo={changeCardInfo} cart={cart} couponInfo={couponInfo} changeInstallment={changeInstallment} />

                        </Form>
                      </>
                      : <></>}
                  </div>

                  <DisplayCartPrice cart={cart} coupon={couponInfo} installmentValue={installmentValue} paymentMethod={paymentMethod} />



                </div>

                <Content id='pagamento' style={{ display: paymentMethod ? 'flex' : 'none', maxWidth: '340px' }}>



                  <aside style={{ display: 'flex', position: 'relative', flexDirection: 'column' }}>
                    {cart?.length < 1 ? <DisableBox /> : <></>}
                    <PaymentMethodForm key={'paymentForm'} courtesyCode={courtesyCode} applyCoupon={applyCoupon} installmentValue={installmentValue} cart={cart} changeInstallment={changeInstallment} paymentMethod={paymentMethod} quantity={quantity} sendPaymentRequest={(context) => {
                      sendPaymentRequest({
                        ...context,
                        total: calculateFinalPrice({ cart, coupon: couponInfo, paymentMethod, installments: installmentValue }),
                        discount: calculateDiscount(couponInfo, cart)?.discountedValue,
                        price: calculateCartPrice(cart),
                        moveToCart

                      })
                    }} couponInfo={couponInfo} />


                  </aside>



                </Content>
              </aside>
            </Content>

          </>
        )}

      </AnimatedDiv>}
    </>
  );
};

export default PaymentMethod;
