import { Form } from '@unform/web';
import React from 'react';
import { FaPlay } from 'react-icons/fa';
import { urlWebsite } from 'src/config';
import { useLanguage } from 'src/hooks/Language';
import { ContentSelectionContainer } from './styles';

const CourseSelection: React.FC<Record<string, any>> = React.memo(({ courseHook }) => {
    const { translate, language } = useLanguage();
    const { courseList, selectCourse, search, filtered, addSearch, selectContent } = courseHook;

    const highlights = courseList?.filter(i => i?.type === 'live');
    const ondemand = courseList?.filter(i => i?.type === 'ondemand');



    return <div className='full-width column'>
        <div className='row full-width'>
            <div className='row-to-column   max-1200' style={{ width: '100%', marginTop: '30px', justifyContent: 'space-between' }}>
                <h2 className='fadeIn' style={{ fontSize: '24px', color: '#fff', margin: '30px 0px' }} >{translate('Biblioteca')}</h2>

                <Form
                    style={{ display: 'flex', alignItems: 'stretch', background: 'none' }}
                    onSubmit={(data) => { addSearch(data?.pesquisa) }}
                >


                    <input style={{ height: '43px', padding: '10px', borderRadius: '5px', border: '0px', marginRight: '10px' }}
                        name="pesquisa"
                        type="text"
                        placeholder={`${translate('Escreva para pesquisar')}...`}
                        value={search}
                        onChange={e => { addSearch(e.target.value); }}
                    />

                    <button className='greenButton2' style={{ height: '43px', margin: 0 }} type="submit">{translate('Pesquisar')}</button>
                </Form>

            </div>
        </div>

        {!search ? <div className='full-width row' style={{ background: 'rgba(0,0,0,0.3)' }}>
            <ContentSelectionContainer >
                <section className='column-start gap full-width' >
                    {!search && highlights?.length > 0 ? <h2 style={{ color: '#fff' }}>DESTAQUES</h2> : <></>}
                    <div className='row-start full-width  flex-wrap  gap-sm' style={{ flexWrap: 'wrap' }}>
                        {
                            !search ? highlights?.map(course => {

                                return <div onClick={() => { selectCourse(course?._id?.toString()) }} className='  cursor row border-radius fadeIn shadow' style={{ width: '100%', borderRadius: '10px', minWidth: '250px', maxWidth: '250px' }}>
                                    <div className='classroomcounter'><FaPlay /><p>{course?.lessons?.length || '0'}</p></div>
                                    <img src={`${urlWebsite}/${course?.image}`} className="full-width scaleOnHover" style={{ borderRadius: '10px' }} />
                                    { }
                                </div >

                            }) : <></>}
                    </div>
                </section>

            </ContentSelectionContainer>
        </div> : <></>}
        {!search ? <ContentSelectionContainer >
            <section className='column-start  gap'>
                {!search && ondemand?.length > 0 ? <h2 style={{ color: '#fff' }}>MÓDULOS ANTERIORES</h2> : <></>}
                <div className='row-start full-width  flex-wrap gap-sm' style={{ flexWrap: 'wrap' }}>
                    {
                        !search ? ondemand?.map(course => {

                            return <div onClick={() => { selectCourse(course?._id?.toString()) }} className='  cursor row border-radius fadeIn shadow' style={{ width: '100%', borderRadius: '10px', minWidth: '250px', maxWidth: '250px' }}>
                                <div className='classroomcounter'><FaPlay /><p>{course?.lessons?.length || '0'}</p></div>
                                <img src={`${urlWebsite}/${course?.image}`} className="full-width scaleOnHover" style={{ borderRadius: '10px' }} />
                                { }
                            </div >

                        }) : <></>}
                </div>
            </section>
        </ContentSelectionContainer> : <></>}
        <ContentSelectionContainer >
            <section className='column-start  gap'>
                {search ? <h2 style={{ color: '#fff' }}>RESULTADO DA PESQUISA</h2> : <></>}
                <div className='row-start full-width flex-wrap  gap-sm' style={{ flexWrap: 'wrap' }}>
                    {search && filtered?.map(lesson => {

                        return <nav onClick={() => { selectCourse(lesson?.course?._id); selectContent(lesson?._id) }} className=' pad cursor column border-radius fadeIn shadow scaleOnHover' style={{ justifyContent: 'center', width: '100%', maxWidth: '200px', borderRadius: '10px', background: '#fff', minHeight: '150px' }}>


                            <h2 style={{ color: '#333', fontSize: '12px', textAlign: 'center', width: '100%', margin: '10px' }}> {lesson?.[`title_${language}`] ? lesson?.[`title_${language}`] : lesson?.[`title`]}</h2>
                            <p style={{ color: '#333', fontSize: '10px', textAlign: 'center', width: '100%' }}>{lesson?.course?.[`title_${language}`] ? lesson?.course?.[`title_${language}`] : lesson?.course?.[`title`]}</p>
                        </nav >


                    })}
                </div>
            </section>
        </ContentSelectionContainer >  </div>



});

export default CourseSelection;