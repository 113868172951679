import React, { useEffect, useState } from 'react';

import {
  FaWhatsapp,
  FaTelegram,
  FaInstagram,
  FaFacebookSquare,
  FaHamburger,
  FaSignInAlt,
  FaSignOutAlt,
  FaSign,
  FaGoogle,
  FaLinkedin,
  FaHome,
  FaTwitter,
  FaYoutube,
  FaVideo,
  FaSpotify,
} from 'react-icons/fa';

import { MdMenu } from 'react-icons/md';


import { useAuth } from '../../hooks/Auth';
import { eventName, haveTranslate, logo, social, urlWebsite } from '../../config';
import {
  Container,
  Navigation,
  NavigationList,
  LinkLi as Link,
  MenuIcon,
  Icon,
  MobileMenuBackground,
  TextMessage,
  IconSet,
  LanguageSet,
  Photo,
  IconSet2,
} from './styles';

import Whatsapp from '../Whatsapp';
import { useLanguage } from '../../hooks/Language';

import { useModal } from '../../hooks/Modal';
import { useConfiguration } from '../../hooks/Configuration';
import MenuMini from '../MenuMini';

interface IOffset extends Element {
  offsetTop: number;
}

export function scrollTo(
  e2: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  targetID2: string,
  duration2 = 200,
): void {

  var targetElement = document.getElementById(targetID2);

  // Verificar se o elemento alvo existe
  if (targetElement) {
    // Rolar até o elemento
    if (window.innerWidth <= 768) {
      targetElement.scrollIntoView();
    }
    else {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

}


export function simpleScrollTo(

  targetID2: string,

): void {

  var targetElement = document.getElementById(targetID2);

  // Verificar se o elemento alvo existe
  if (targetElement) {
    // Rolar até o elemento
    if (window.innerWidth <= 768) {
      targetElement.scrollIntoView();
    }
    else {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

}


const WhatsappIcon: React.FC = () => {
  return (
    <Icon
      style={{ margin: '0px' }}
      background="rgb(0,150,100)"
      title="Whatsapp"
      href="https://api.whatsapp.com/send?l=pt&phone=5551980567890"
    >
      <FaWhatsapp />
    </Icon>
  );
};

interface IIcons {
  message?: string;
  showText?: boolean;
  backgroundFill?: boolean;
  socialIcons?: Record<string, any>;
  color?: string;
}

export const ContactIconsOrange: React.FC<IIcons> = ({
  message = '',
  showText = false,
  backgroundFill = true,
  socialIcons = social,
  color = '',
}) => {
  return (
    socialIcons && (
      <IconSet2 column={showText}>
        {message !== '' && (
          <div className="messageSet">
            <TextMessage>{message}</TextMessage>
          </div>
        )}
        <div className="iconSet">
          {socialIcons.facebook && (
            <Icon
              color={color}
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Facebook"
              href={`https://www.facebook.com/${socialIcons.facebook}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaFacebookSquare />
              {' '}
              {showText && <span>Facebook</span>}
            </Icon>
          )}

          {socialIcons.instagram && (
            <Icon
              color={color}
              extend={showText}
              className="socialIcon"
              title="Instagram"
              target="_blank"
              href={`https://www.instagram.com/${socialIcons.instagram}`}
              background={backgroundFill === true ? 'rgb(250,0,100)' : 'none'}
            >
              <FaInstagram />
              {' '}
              {showText && <span>Instagram</span>}
            </Icon>
          )}

          {socialIcons.linkedin && (
            <Icon
              color={color}
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Linkedin"
              href={`https://www.linkedin.com/${socialIcons.linkedin}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaLinkedin />
              {' '}
              {showText && <span>Linkedin</span>}
            </Icon>
          )}

          {socialIcons.youtube && (
            <Icon
              color={color}
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Youtube"
              href={`https://www.youtube.com/${socialIcons.youtube}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaYoutube />
              {' '}
              {showText && <span>Youtube</span>}
            </Icon>
          )}

          {socialIcons.twitter && (
            <Icon
              color={color}
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Twitter"
              href={`https://www.twitter.com/${socialIcons.twitter}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaTwitter />
              {' '}
              {showText && <span>Twitter</span>}
            </Icon>
          )}

          {socialIcons.whatsapp && (
            <Icon
              color={color}
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Whatsapp"
              href={`https://api.whatsapp.com/send?l=pt&phone=${socialIcons.whatsapp
                }&text=${encodeURI(socialIcons?.whatsapp_message ? socialIcons?.whatsapp_message : `Olá! Estou no evento ${eventName}!`)}`}
            >
              <FaWhatsapp />

              {showText && <span>Whatsapp</span>}
            </Icon>
          )}

          {socialIcons.website && (
            <Icon
              color={color}
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Website"
              href={socialIcons.website}
            >
              <FaHome />

              {showText && <span>Website</span>}
            </Icon>
          )}

          {socialIcons.zoom && (
            <Icon
              color={color}
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Zoom"
              href={socialIcons.zoom}
            >
              <FaVideo />

              {showText && <span>Zoom</span>}
            </Icon>
          )}

          {socialIcons.meet && (
            <Icon
              color={color}
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Reunião no Goole Meet"
              href={socialIcons.meet}
            >
              <FaGoogle />

              {showText && <span>Google Meet</span>}
            </Icon>
          )}

          {socialIcons.spotify && (
            <Icon
              color={color}
              extend={showText}
              className="socialIcon"
              title="spotify"
              target="_blank"
              href={`${socialIcons.spotify}`}
              background={backgroundFill === true ? 'rgb(250,0,100)' : 'none'}
            >
              <FaSpotify />
              {' '}
              {showText && <span>Spotify</span>}
            </Icon>
          )}
        </div>
      </IconSet2>
    )
  );
};


const ContactIcons: React.FC<IIcons> = ({
  message = '',
  showText = false,
  backgroundFill = true,
  socialIcons = social,
  color = '',
}) => {
  return (
    socialIcons && (
      <IconSet column={showText}>
        {message !== '' && (
          <div className="messageSet">
            <TextMessage>{message}</TextMessage>
          </div>
        )}
        <div className="iconSet">
          {socialIcons.facebook && (
            <Icon
              color={color}
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Facebook"
              href={`https://www.facebook.com/${socialIcons.facebook}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaFacebookSquare />
              {' '}
              {showText && <span>Facebook</span>}
            </Icon>
          )}

          {socialIcons.instagram && (
            <Icon
              color={color}
              extend={showText}
              className="socialIcon"
              title="Instagram"
              target="_blank"
              href={`https://www.instagram.com/${socialIcons.instagram}`}
              background={backgroundFill === true ? 'rgb(250,0,100)' : 'none'}
            >
              <FaInstagram />
              {' '}
              {showText && <span>Instagram</span>}
            </Icon>
          )}

          {socialIcons.linkedin && (
            <Icon
              color={color}
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Linkedin"
              href={`https://www.linkedin.com/${socialIcons.linkedin}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaLinkedin />
              {' '}
              {showText && <span>Linkedin</span>}
            </Icon>
          )}

          {socialIcons.youtube && (
            <Icon
              color={color}
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Youtube"
              href={`https://www.youtube.com/${socialIcons.youtube}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaYoutube />
              {' '}
              {showText && <span>Youtube</span>}
            </Icon>
          )}

          {socialIcons.twitter && (
            <Icon
              color={color}
              target="_blank"
              extend={showText}
              className="socialIcon"
              title="Twitter"
              href={`https://www.twitter.com/${socialIcons.twitter}`}
              background={backgroundFill === true ? '#3d6ad6' : 'none'}
            >
              <FaTwitter />
              {' '}
              {showText && <span>Twitter</span>}
            </Icon>
          )}

          {socialIcons.whatsapp && (
            <Icon
              color={color}
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Whatsapp"
              href={`https://api.whatsapp.com/send?l=pt&phone=${socialIcons.whatsapp
                }&text=${encodeURI(socialIcons?.whatsapp_message ? socialIcons?.whatsapp_message : `Olá! Estou no website da ${eventName}!`)}`}
            >
              <FaWhatsapp />

              {showText && <span>Whatsapp</span>}
            </Icon>
          )}

          {socialIcons.website && (
            <Icon
              color={color}
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Website"
              href={socialIcons.website}
            >
              <FaHome />

              {showText && <span>Website</span>}
            </Icon>
          )}

          {socialIcons.zoom && (
            <Icon
              color={color}
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Zoom"
              href={socialIcons.zoom}
            >
              <FaVideo />

              {showText && <span>Zoom</span>}
            </Icon>
          )}

          {socialIcons.meet && (
            <Icon
              color={color}
              extend={showText}
              target="_blank"
              className="socialIcon"
              background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
              title="Reunião no Goole Meet"
              href={socialIcons.meet}
            >
              <FaGoogle />

              {showText && <span>Google Meet</span>}
            </Icon>
          )}

          {socialIcons.spotify && (
            <Icon
              color={color}
              extend={showText}
              className="socialIcon"
              title="spotify"
              target="_blank"
              href={`${socialIcons.spotify}`}
              background={backgroundFill === true ? 'rgb(250,0,100)' : 'none'}
            >
              <FaSpotify />
              {' '}
              {showText && <span>Spotify</span>}
            </Icon>
          )}
        </div>
      </IconSet>
    )
  );
};

interface IMenu {
  menuList: Record<string, any>;
  fixedMenu: boolean;
}

const Menu: React.FC<IMenu> = ({ menuList, fixedMenu = true }) => {
  const [sticky, setSticky] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);

  const LoginIcons: React.FC = () => {
    const { signOut, token } = useAuth();

    return (
      <>
        {' '}
        {token && (
          <li key="logout">
            <Link style={{ color: configuration?.current_event_id_response?.theme?.menu_color || '#333' }}
              onClick={e => {
                signOut();
                setActiveMenu(false);
              }}
              to="/"
              title="Sair"
            >
              {translate('Sair')}
            </Link>
          </li>
        )}
      </>
    );
  };

  const LanguageIcons: React.FC = () => {
    const { changeLanguage, language, availableLanguages } = useLanguage();

    return (
      <LanguageSet>

        {availableLanguages.map(lang => {

          return <div style={{ borderBottom: language === lang.code ? '4px solid #A71C20' : '0px', color: language === lang.code ? '#A71C20' : '#333' }} onClick={() => changeLanguage(lang.code)}>
            {lang.title}
          </div>
        })}

      </LanguageSet>
    );
  };

  useEffect(() => {
    if (fixedMenu) {
      const header = document.querySelector<IOffset>('.menuHeader');

      const offsetValue = header?.offsetTop || 0;

      const offsetMonitor = (): void => {
        if (window.pageYOffset > offsetValue) {
          setSticky(true);
        } else {
          setSticky(false);
        }
      };

      window.onscroll = () => {
        offsetMonitor();
      };
    }
  }, [fixedMenu, sticky]);

  const handleActiveMenu = (): void => {
    setActiveMenu(!activeMenu);
  };

  const { translate } = useLanguage();
  const { user } = useAuth();
  const { addModal } = useModal();

  const { configuration } = useConfiguration();

  return (<>
    <Container
      sticky={sticky}
      fixedMenu={fixedMenu}
      activeMenu={activeMenu}
      className="menuHeader  shadow"
    >
      <Navigation className='max-1400' style={{ background: configuration?.current_event_id_response?.theme?.menu_background || 'none', margin: '0px auto' }} sticky={sticky} fixedMenu={fixedMenu}>
        <div className="logoHolder" style={{ display: 'flex', alignItems: 'center' }}>
          {' '}

          <Link to="/" >
            {configuration?.logo ? <img className="logo2" src={`${urlWebsite}/${configuration?.logo}`} /> : <></>}
          </Link>
          {configuration?.logo_main ? <Link to="/" >
            <img className="logo1" src={`${urlWebsite}/${configuration?.logo_main}`} />

          </Link> : <></>}

          <Link to="/" style={{ zIndex: 10, position: 'relative' }} >
            <img className="logomain" src={`/assets/logo2.png`} />

          </Link>

          {/*   <a href="https://navpro.dasa.com.br/" target='_BLANK' >
            <img className="logomain2" src={`/apoio/logonav.png`} />

  </a> */}

          {configuration?.logo_adicional ? <Link to="/" ><img className="logo1" src={`${urlWebsite}/${configuration?.logo_adicional}`} /></Link> : <></>}
          {configuration?.logo_adicional2 ? <Link to="/" ><img className="logo1" src={`${urlWebsite}/${configuration?.logo_adicional2}`} /></Link> : <></>}

        </div>
        <MobileMenuBackground activeMenu={activeMenu} />
        <NavigationList activeMenu={activeMenu}>
          {configuration?.active_pages?.map((menu: Record<string, any>) => {


            const {
              title,
              target,
              icon,
              url = '',
              type = 'menu',
              fluid = 'no',
              external = 'no',
              active = 'no',
            } = menu;

            const LinkMe: React.FC = () => {
              if (active === 'yes' && external === 'yes') {
                return (
                  <a style={{ color: configuration?.current_event_id_response?.theme?.menu_color || '#333' }}
                    title={title}
                    href={target}
                    onClick={() => setActiveMenu(false)}
                  >
                    {icon}

                    {title}
                  </a>
                );
              }
              else if (type === 'page') {
                return (
                  <Link style={{ color: configuration?.current_event_id_response?.theme?.menu_color || '#333' }}
                    onClick={e => {
                      if (window.location.pathname === '/') {
                        setActiveMenu(false);
                        { url && scrollTo(e, url) };
                      } else {
                        setActiveMenu(false);
                        window.location.href = `/#${url}`;
                      }
                    }}
                    to={`/#${url}`}
                    title={translate(title)}
                  >
                    {icon}
                    {translate(title)}
                  </Link>
                );
              }

              else if (active === 'yes' && menu?.menu === 'yes') {
                return (
                  <Link style={{ color: configuration?.current_event_id_response?.theme?.menu_color || '#333' }}
                    title={title}
                    to={url}
                    onClick={() => setActiveMenu(false)}
                  >
                    {icon}

                    {translate(title)}
                  </Link>
                );
              }
              return <></>;
            };

            return (
              <>
                {' '}
                {active === 'yes' && (
                  <li key={url} style={{ color: configuration?.current_event_id_response?.theme?.menu_color || '#333' }}>
                    <LinkMe />
                  </li>
                )}
              </>
            );
          })}

          <li className="closeMenu" onClick={() => handleActiveMenu()} style={{ color: configuration?.current_event_id_response?.theme?.menu_color || '#333', fontWeight: 'bold' }}>
            {'<<'}
            Voltar
          </li>

          <div className="row">
            <LoginIcons />


            { /* user  && <Photo title="Meu Perfil" onClick={() => {
            addModal({title:'', content: <Profile/>, theme:"whiteModal" })
          }} background={user.image ? `${urlWebsite}/${user.image}` : '/apoio/user.jpg'}><p>Atualizar<br/>Perfil</p></Photo> */}

            {configuration?.theme_data?.show_social_icons !== 'no' && <ContactIcons />}


          </div>
        </NavigationList>
        <div className='row' style={{ maxWidth: '180px' }}>
          {haveTranslate && <LanguageIcons />}
          <MenuMini />
        </div>
      </Navigation>

    </Container>
  </>
  );
};

export { WhatsappIcon, ContactIcons, Menu };
