import React from 'react';
import ChatRoom from '../Chat';
import RoomConfiguration from './modules/ContentClassRoom/modules/RoomConfigurations';
import useCourse from './hook/courseHook';
import ContentClassRoom from './modules/ContentClassRoom';
import CourseSelection from './modules/CourseSelection';
import SidebarClassroom from './modules/SidebarClassroom';
import { ContentContainer } from './styles';
const CourseClassroom: React.FC<Record<string, any>> = () => {

    const courseHook = useCourse();
    const { currentView, ready, content, course } = courseHook;


    if (!ready) {
        return <div className='full-width row' style={{ minHeight: '100vh' }}><h2>Carregando...</h2></div>
    }

    if (currentView === 'list') {
        return <div className='row-to-column full-width' style={{ alignItems: 'flex-start', flex: 1, background: 'rgba(0,0,150,0.5)', position: 'relative' }}>
            <div className='pattern' />
            <CourseSelection courseHook={courseHook} />

        </div>
    }
    if (currentView === 'course' || currentView === 'content') {

        return <>
            <RoomConfiguration content={content} courseHook={courseHook} />

            <ContentContainer style={{ flex: 1, background: 'rgba(0,0,150,0.5)', position: 'relative' }}>
                <div className='pattern' />


                <SidebarClassroom courseHook={courseHook} />



                <ContentClassRoom courseHook={courseHook} />

                <div className='column pad' style={{ display: course?.type === 'live' ? 'flex' : 'none' }} >

                    <ChatRoom key={`${content._id}`} slides={content?.slides || []}
                        room_name={content?.title || ''} poster_id={content?.poster_id}
                        id={content._id}
                    />
                </div>

            </ContentContainer>
        </>

    }

    return <></>

}

export default CourseClassroom;